import { Breadcrumbs } from 'components/_layout/Breadcrumbs/Breadcrumbs';
import React, { useMemo, useRef, useState } from 'react';
import ImageMapper, { AreaEvent, CustomArea } from 'react-img-mapper';
import { ArrowLeft } from 'shared/svg/arrows';
import { GarageType, StorageType } from 'types/CommonTypes';
import {
    ContentWrapper,
    HeroLink,
    HeroTitle,
    HeroWrapper,
    ImageMapperWrapper,
    InfoBox,
    InfoBoxWrapper,
    LocalWrapper,
    SelectWrapper,
    StyledInfoBoxTitle,
    StyledStatusText,
    StyledTooltipFlatStatus,
    StyledTooltipLocal,
    StyledTooltipText,
    StyledtStatusTextFree,
    StyledtStatusTextReserve,
    StyledtStatusTextSold,
    Tooltip,
    Wrapper,
} from './MultiBuildingGarageHero.styled';
import { useContainerDimensions } from 'utils/hooks/useContainerDimensions';
import { useImageRatio } from 'utils/hooks/useImageRatio';
import { Select } from 'components/Select/Select';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import {
    GARAGES_STATISTICS,
    STORAGE_ROOMS_STATISTICS,
} from 'src/apollo/queries';

interface MultiBuildingGarageHeroProps {
    availableGarages: number;
    availableStorageRooms: number;
    bookedGarages: number;
    bookedStorageRooms: number;
    breadcrumbText: string;
    investmentSlug: string;
    searchGarageOrStorageByImage?: any;
    multiSearchGarageOrStorageByImage?: any;
    soldGarages: number;
    soldStorageRooms: number;
    isMultiBuilding: boolean;
    id: string;
    availableBuildings: any;
}

interface StateTypes {
    localNumber: string | null;
    price: string | null;
    status: string | null;
    tooltipIsVisible: boolean;
    type: string | null;
    yardage: string | null;
}

export const MultiBuildingGarageHero = ({
    availableGarages,
    availableStorageRooms,
    bookedGarages,
    bookedStorageRooms,
    breadcrumbText,
    investmentSlug,
    searchGarageOrStorageByImage,
    multiSearchGarageOrStorageByImage,
    soldGarages,
    soldStorageRooms,
    isMultiBuilding,
    id,
    availableBuildings,
}: MultiBuildingGarageHeroProps) => {
    const { control, watch } = useFormContext();
    const selectBuilding = watch('investmentBuilding');

    const { data: garagesData } = useQuery(GARAGES_STATISTICS, {
        variables: {
            investmentBuilding: selectBuilding?.[0]?.value
                ? selectBuilding[0].value
                : availableBuildings?.[0]?.value,
            id,
        },
        skip: !isMultiBuilding,
    });

    const { data: storageRoomsData } = useQuery(STORAGE_ROOMS_STATISTICS, {
        variables: {
            investmentBuilding: selectBuilding?.[0]?.value
                ? selectBuilding[0].value
                : availableBuildings?.[0]?.value,
            id,
        },
        skip: !isMultiBuilding,
    });

    const statistics = useMemo(
        () => ({
            garages: {
                available: isMultiBuilding
                    ? garagesData?.availableGarages?.pageInfo?.total
                    : availableGarages,
                booked: isMultiBuilding
                    ? garagesData?.bookedGarages?.pageInfo?.total
                    : bookedGarages,
                sold: isMultiBuilding
                    ? garagesData?.soldGarages?.pageInfo?.total
                    : soldGarages,
            },
            storageRooms: {
                available: isMultiBuilding
                    ? storageRoomsData?.availableStorages?.pageInfo?.total
                    : availableStorageRooms,
                booked: isMultiBuilding
                    ? storageRoomsData?.bookedStorages?.pageInfo?.total
                    : bookedStorageRooms,
                sold: isMultiBuilding
                    ? storageRoomsData?.soldStorages?.pageInfo?.total
                    : soldStorageRooms,
            },
        }),
        [
            garagesData,
            storageRoomsData,
            availableGarages,
            availableStorageRooms,
            bookedGarages,
            bookedStorageRooms,
            isMultiBuilding,
            selectBuilding,
            soldGarages,
            soldStorageRooms,
        ],
    );

    const mapGarages = (item: GarageType) => ({
        coords: item?.coordinates
            ? item?.coordinates?.split(',')?.map((i) => Number(i))
            : [],
        id: 'G' + item?.garage?.investmentGarage?.garageNumber,
        shape: 'poly',
        preFillColor:
            item?.garage?.investmentGarage?.garageStatus?.name === 'Wolne'
                ? '#46A25A70'
                : item?.garage?.investmentGarage?.garageStatus?.name ===
                  'Sprzedane'
                ? '#DD432670'
                : '#E0B85270',
        status: item?.garage?.investmentGarage?.garageStatus?.name,
        area: item?.garage?.investmentGarage?.garageArea,
        number: item?.garage?.investmentGarage?.garageNumber,
        price: item?.garage?.investmentGarage?.garagePrice,
        href: '',
    });

    const mapStorages = (item: StorageType) => ({
        coords: item?.coordinates
            ? item?.coordinates?.split(',')?.map((i) => Number(i))
            : [],
        id: 'S' + item?.storageRoom?.investmentStorageRoom?.storageRoomNumber,
        shape: 'poly',
        preFillColor:
            item?.storageRoom?.investmentStorageRoom?.storageRoomStatus.name ===
            'Wolne'
                ? '#46A25A70'
                : item?.storageRoom?.investmentStorageRoom?.storageRoomStatus
                      ?.name === 'Sprzedane'
                ? '#DD432670'
                : '#E0B85270',
        status: item?.storageRoom?.investmentStorageRoom?.storageRoomStatus
            .name,
        area: item?.storageRoom?.investmentStorageRoom?.storageRoomArea,
        number: item?.storageRoom?.investmentStorageRoom?.storageRoomNumber,
        price: item?.storageRoom?.investmentStorageRoom?.storageRoomPrice,
        href: '',
    });

    const garageSearchMap = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return (
                    multiSearchGarageOrStorageByImage?.[0]?.garagesSearchMap?.map(
                        mapGarages,
                    ) ?? []
                );
            } else {
                return (
                    multiSearchGarageOrStorageByImage?.[1]?.garagesSearchMap?.map(
                        mapGarages,
                    ) ?? []
                );
            }
        } else
            return searchGarageOrStorageByImage?.garagesSearchMap
                ? searchGarageOrStorageByImage?.garagesSearchMap?.map(
                      mapGarages,
                  )
                : [];
    }, [
        searchGarageOrStorageByImage,
        isMultiBuilding,
        multiSearchGarageOrStorageByImage,
        selectBuilding,
        mapGarages,
    ]);

    const storageSearchMap = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return (
                    multiSearchGarageOrStorageByImage?.[0]?.storagesSearchMap?.map(
                        mapStorages,
                    ) ?? []
                );
            } else {
                return (
                    multiSearchGarageOrStorageByImage?.[1]?.storagesSearchMap?.map(
                        mapStorages,
                    ) ?? []
                );
            }
        } else
            return searchGarageOrStorageByImage?.storagesSearchMap
                ? searchGarageOrStorageByImage?.storagesSearchMap?.map(
                      mapStorages,
                  )
                : [];
    }, [
        searchGarageOrStorageByImage,
        isMultiBuilding,
        mapStorages,
        selectBuilding,
        multiSearchGarageOrStorageByImage,
    ]);

    const getImageWidth = () => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return multiSearchGarageOrStorageByImage?.[0]?.searchMapImage
                    ?.mediaDetails?.width;
            } else {
                return multiSearchGarageOrStorageByImage?.[1]?.searchMapImage
                    ?.mediaDetails?.width;
            }
        } else
            return searchGarageOrStorageByImage?.searchMapImage?.mediaDetails
                ?.width;
    };

    const getImageUrl = () => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return multiSearchGarageOrStorageByImage?.[0]?.searchMapImage
                    ?.sourceUrl;
            } else {
                return multiSearchGarageOrStorageByImage?.[1]?.searchMapImage
                    ?.sourceUrl;
            }
        } else return searchGarageOrStorageByImage?.searchMapImage?.sourceUrl;
    };

    const [tooltip, setTooltip] = useState<StateTypes>({
        localNumber: '',
        price: '',
        status: '',
        tooltipIsVisible: false,
        type: '',
        yardage: '',
    });
    const tooltipRef = useRef<HTMLSpanElement>(null);
    let cursorPositionX = 0;
    let cursorPositionY = 0;

    const imagesBoxRef = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(imagesBoxRef);
    const currentRatio = useImageRatio(
        searchGarageOrStorageByImage?.searchMapImage?.mediaDetails,
    );
    const imagesBoxWidth = width;
    const imagesBoxHeight = imagesBoxWidth * currentRatio;

    const moveOnArea = (area: CustomArea, index: number, event: AreaEvent) => {
        cursorPositionX = event.clientX;
        cursorPositionY = event.clientY;
        tooltipRef.current!.style.left = `${cursorPositionX}px`;
        tooltipRef.current!.style.top = `${cursorPositionY - 90}px`;
    };

    const enterArea = (area: any, index: number, event: AreaEvent) => {
        setTooltip({
            localNumber: area?.number,
            price: area?.price,
            status: area?.status,
            tooltipIsVisible: true,
            type: area?.id?.slice(0, 1) === 'S' ? 'Komórka' : 'Miejsce',
            yardage: area?.area,
        });
    };

    const leaveArea = () => {
        setTooltip({
            localNumber: null,
            price: null,
            status: null,
            tooltipIsVisible: false,
            type: null,
            yardage: null,
        });
    };

    return (
        <Wrapper>
            <Breadcrumbs text={breadcrumbText} />
            <HeroWrapper>
                <HeroTitle>Wybierz lokal</HeroTitle>
                <HeroLink to={'/' + investmentSlug + '/mieszkania'}>
                    <ArrowLeft />
                    Wróć do wyboru piętra
                </HeroLink>
            </HeroWrapper>
            <ContentWrapper>
                {isMultiBuilding && (
                    <SelectWrapper>
                        <Select
                            options={availableBuildings}
                            name="investmentBuilding"
                            control={control}
                            label=""
                            closeMenuOnSelect
                        />
                    </SelectWrapper>
                )}
                <LocalWrapper>
                    <ImageMapperWrapper ref={imagesBoxRef}>
                        <ImageMapper
                            imgWidth={getImageWidth()}
                            onMouseEnter={(
                                area: CustomArea,
                                index: number,
                                event: AreaEvent,
                            ) => {
                                enterArea(area, index, event);
                            }}
                            onMouseLeave={() => leaveArea()}
                            onMouseMove={(
                                area: CustomArea,
                                index: number,
                                event: AreaEvent,
                            ) => moveOnArea(area, index, event)}
                            src={getImageUrl()}
                            fillColor={'rgb(0, 0, 0, 0.1)'}
                            width={imagesBoxWidth}
                            height={imagesBoxHeight}
                            map={{
                                name: 'garageAndStorage',
                                areas: garageSearchMap?.concat(
                                    storageSearchMap,
                                ),
                            }}
                            key={'GarageAndStorage'}
                        />
                        <Tooltip
                            ref={tooltipRef}
                            isVisible={tooltip?.tooltipIsVisible}
                        >
                            <StyledTooltipLocal>
                                {tooltip?.type} {tooltip?.localNumber}
                            </StyledTooltipLocal>
                            <StyledTooltipFlatStatus isFree={tooltip?.status}>
                                {tooltip?.status}
                            </StyledTooltipFlatStatus>
                            <StyledTooltipText>
                                Metraż: {tooltip?.yardage} m<sup>2</sup>
                            </StyledTooltipText>
                            <StyledTooltipText>
                                Cena:{' '}
                                {tooltip?.price ? tooltip?.price + 'zł' : '-'}
                            </StyledTooltipText>
                        </Tooltip>
                    </ImageMapperWrapper>

                    <InfoBoxWrapper>
                        <InfoBox>
                            <StyledInfoBoxTitle>Garaże</StyledInfoBoxTitle>
                            <StyledStatusText>
                                <StyledtStatusTextFree>
                                    Wolne
                                </StyledtStatusTextFree>
                                {statistics.garages.available}
                            </StyledStatusText>
                            <StyledStatusText>
                                <StyledtStatusTextReserve>
                                    Zarezerwowane
                                </StyledtStatusTextReserve>
                                {statistics.garages.booked}
                            </StyledStatusText>
                            <StyledStatusText>
                                <StyledtStatusTextSold>
                                    Sprzedane
                                </StyledtStatusTextSold>
                                {statistics.garages.sold}
                            </StyledStatusText>
                        </InfoBox>
                        <InfoBox>
                            <StyledInfoBoxTitle>Komórki</StyledInfoBoxTitle>
                            <StyledStatusText>
                                <StyledtStatusTextFree>
                                    Wolne
                                </StyledtStatusTextFree>
                                {statistics.storageRooms.available}
                            </StyledStatusText>
                            <StyledStatusText>
                                <StyledtStatusTextReserve>
                                    Zarezerwowane
                                </StyledtStatusTextReserve>
                                {statistics.storageRooms.booked}
                            </StyledStatusText>
                            <StyledStatusText>
                                <StyledtStatusTextSold>
                                    Sprzedane
                                </StyledtStatusTextSold>
                                {statistics.storageRooms.sold}
                            </StyledStatusText>
                        </InfoBox>
                    </InfoBoxWrapper>
                </LocalWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};
