import { GarageTable } from 'components/Tables/GarageTable';
import { StorageTable } from 'components/Tables/StorageTable';
import React from 'react';
import { GarageListMobile } from 'sections/GarageListMobile/GarageListMobile';
import {
    Wrapper,
    SectionTitleWrapper,
    TableStorageWrapper,
    TableWrapper,
    StyledButton,
    TableGarageWrapper,
    SectionTitle,
    SelectWrapper,
} from './MultiGaragesAndStoragesList.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { StorageListMobile } from 'sections/StorageListMobile/StorageListMobile';
import { SingleGarageType, SingleStorageRoomType } from 'types/CommonTypes';
import { StorageInfo } from 'sections/StorageInfo/StorageInfo';
import { Select } from 'components/Select/Select';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';

interface MultiGaragesAndStoragesListProps {
    additionalInfo: string;
    garagesData: SingleGarageType[];
    hideStoragesMap: boolean;
    investmentSlug: string;
    storagesData: SingleStorageRoomType[];
    isMultiBuilding: boolean;
    availableBuildings: any;
}

export const MultiGaragesAndStoragesList = ({
    additionalInfo,
    garagesData,
    hideStoragesMap,
    investmentSlug,
    storagesData,
    isMultiBuilding,
    availableBuildings,
}: MultiGaragesAndStoragesListProps) => {
    const isMobile = useBreakpoint('sm');

    const { control } = useFormContext();

    return (
        <Wrapper>
            {hideStoragesMap && <StorageInfo text={additionalInfo} />}
            <SectionTitleWrapper>
                <SectionTitle>Garaże</SectionTitle>
                {isMobile && isMultiBuilding && (
                    <SelectWrapper>
                        <Select
                            options={availableBuildings}
                            name="investmentBuilding"
                            control={control}
                            label=""
                            closeMenuOnSelect
                        />
                    </SelectWrapper>
                )}
                <StyledButton to={'/' + investmentSlug + '/mieszkania'}>
                    Zobacz mieszkania
                </StyledButton>
            </SectionTitleWrapper>
            {!isMobile && (
                <TableGarageWrapper hideStoragesMap={hideStoragesMap}>
                    <TableWrapper>
                        <GarageTable data={garagesData} />
                    </TableWrapper>
                </TableGarageWrapper>
            )}
            {isMobile && (
                <GarageListMobile
                    data={garagesData}
                    investmentSlug={investmentSlug}
                />
            )}
            {!hideStoragesMap && (
                <SectionTitleWrapper>
                    <SectionTitle>Komórki lokatorskie</SectionTitle>
                    <StyledButton to={'/' + investmentSlug + '/mieszkania'}>
                        Zobacz mieszkania
                    </StyledButton>
                </SectionTitleWrapper>
            )}
            {isMobile && !hideStoragesMap && (
                <StorageListMobile
                    data={storagesData}
                    investmentSlug={investmentSlug}
                />
            )}
            {!hideStoragesMap && (
                <TableStorageWrapper>
                    <TableWrapper>
                        <StorageTable data={storagesData} />
                    </TableWrapper>
                </TableStorageWrapper>
            )}
        </Wrapper>
    );
};
