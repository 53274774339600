import { MultiBuildingGarageHero } from 'components/MultiBuildingGarageHero/MultiBuildingGarageHero';
import Layout from 'layout/index';
import React, { useMemo } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { MultiGaragesAndStoragesList } from 'sections/MultiGaragesAndStoragesList/MultiGaragesAndStoragesList';
import { getDisabledPages } from 'utils/utils';

interface MultiBuildingGarazePageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: MultiBuildingGarazePageProps) => {
    const methods = useForm<FieldValues>({
        mode: 'onChange',
    });

    const { watch } = methods;
    const selectBuilding = watch('investmentBuilding');

    const isMultiBuilding =
        pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage
            ?.enableMultiGarageSearch;

    const availableBuildings =
        pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage?.multiSearchGarageOrStorageByImage?.map(
            (item: any) => {
                return {
                    value: item?.buildingTax,
                    label: `Budynek ${item?.buildingTax}`,
                };
            },
        );

    const garagesData = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return (
                    pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage?.multiSearchGarageOrStorageByImage?.[0]?.garagesSearchMap?.map(
                        (item: any) => ({
                            ...item?.garage?.investmentGarage,
                            garageStatus:
                                item?.garage?.investmentGarage?.garageStatus
                                    ?.name,
                        }),
                    ) ?? []
                );
            } else {
                return (
                    pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage?.multiSearchGarageOrStorageByImage?.[1]?.garagesSearchMap?.map(
                        (item: any) => ({
                            ...item?.garage?.investmentGarage,
                            garageStatus:
                                item?.garage?.investmentGarage?.garageStatus
                                    ?.name,
                        }),
                    ) ?? []
                );
            }
        } else
            return pageContext?.data?.garages?.edges?.map((item: any) => ({
                ...item?.node?.investmentGarage,
                garageStatus: item?.node?.investmentGarage?.garageStatus?.name,
            }));
    }, [
        isMultiBuilding,
        pageContext,
        pageContext?.data?.garages?.edges,
        selectBuilding,
    ]);

    const storagesData = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return (
                    pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage?.multiSearchGarageOrStorageByImage?.[0]?.storagesSearchMap?.map(
                        (item: any) => ({
                            ...item?.storageRoom?.investmentStorageRoom,
                            storageRoomStatus:
                                item?.storageRoom?.investmentStorageRoom
                                    ?.storageRoomStatus?.name,
                        }),
                    ) ?? []
                );
            } else {
                return (
                    pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage?.multiSearchGarageOrStorageByImage?.[1]?.storagesSearchMap?.map(
                        (item: any) => ({
                            ...item?.storageRoom?.investmentStorageRoom,
                            storageRoomStatus:
                                item?.storageRoom?.investmentStorageRoom
                                    ?.storageRoomStatus?.name,
                        }),
                    ) ?? []
                );
            }
        } else
            return pageContext?.data?.storages?.edges?.map((item: any) => ({
                ...item?.node?.investmentStorageRoom,
                storageRoomStatus:
                    item?.node?.investmentStorageRoom?.storageRoomStatus?.name,
            }));
    }, [
        pageContext?.data?.storages?.edges,
        isMultiBuilding,
        pageContext,
        selectBuilding,
    ]);

    const searchByImage =
        pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage;

    const getAdditionalInfoText = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return searchByImage?.multiSearchGarageOrStorageByImage?.[0]
                    ?.storagesAdditionalInfo;
            } else {
                return searchByImage?.multiSearchGarageOrStorageByImage?.[1]
                    ?.storagesAdditionalInfo;
            }
        } else
            return searchByImage?.searchGarageOrStorageByImage
                ?.storagesAdditionalInfo;
    }, [isMultiBuilding, searchByImage, selectBuilding]);

    const hideStorageMap = useMemo(() => {
        if (isMultiBuilding) {
            if (
                selectBuilding?.[0]?.value === '68' ||
                !selectBuilding?.[0]?.value
            ) {
                return !!searchByImage?.multiSearchGarageOrStorageByImage?.[0]
                    ?.hideStoragesMap;
            } else {
                return !!searchByImage?.multiSearchGarageOrStorageByImage?.[1]
                    ?.hideStoragesMap;
            }
        } else {
            return searchByImage?.searchGarageOrStorageByImage?.hideStoragesMap;
        }
    }, [isMultiBuilding, searchByImage, selectBuilding]);

    const pageTitle = `${pageContext?.title} - Mieszkania - Garaże i komórki lokatorskie`;
    return (
        <FormProvider {...methods}>
            <Layout
                displayPartnersPage={
                    pageContext?.data?.GaragesAndStoragesByImage?.investment
                        ?.enablePartnersPage
                }
                headerData={pageContext?.headerData}
                investmentSlug={pageContext?.slug}
                pageTitle={pageTitle}
                location={location}
                displayBanner
                investmentDisabledPages={getDisabledPages(
                    pageContext?.data?.wpInvestment?.investment,
                )}
            >
                <>
                    <MultiBuildingGarageHero
                        investmentSlug={pageContext?.slug}
                        breadcrumbText="Mieszkania"
                        availableGarages={
                            pageContext?.data?.availableGarages?.count
                        }
                        availableStorageRooms={
                            pageContext?.data?.availableStorageRooms?.count
                        }
                        bookedGarages={pageContext?.data?.bookedGarages?.count}
                        bookedStorageRooms={
                            pageContext?.data?.bookedStorageRooms?.count
                        }
                        searchGarageOrStorageByImage={
                            searchByImage?.searchGarageOrStorageByImage
                        }
                        isMultiBuilding={isMultiBuilding}
                        multiSearchGarageOrStorageByImage={
                            searchByImage?.multiSearchGarageOrStorageByImage
                        }
                        soldGarages={pageContext?.data?.soldGarages?.count}
                        soldStorageRooms={
                            pageContext?.data?.soldStorageRooms?.count
                        }
                        id={pageContext?.data?.GaragesAndStoragesByImage?.id}
                        availableBuildings={availableBuildings}
                    />
                    {garagesData && storagesData && (
                        <MultiGaragesAndStoragesList
                            isMultiBuilding={isMultiBuilding}
                            investmentSlug={pageContext?.slug}
                            garagesData={garagesData}
                            storagesData={storagesData}
                            additionalInfo={getAdditionalInfoText}
                            hideStoragesMap={hideStorageMap}
                            availableBuildings={availableBuildings}
                        />
                    )}
                </>
            </Layout>
        </FormProvider>
    );
};
